<script setup lang="ts">
const cacheConfigStore = useWorkBenchCacheConfigStore()
const { project } = storeToRefs(cacheConfigStore)
const appListStore = useAppListStore()
const sortedCategoryApps = ref<any[]>([])

const styleObj = {
  backgroundImage: `url(${project.value?.workbenchBackgroundImage})`,
}

const bgStyle = computed(() => {
  if (project.value?.workbenchBackgroundImage) {
    return styleObj
  }
  else { return {} }
})

onMounted(async () => {
  // sortedCategoryApps.value = await cacheConfigStore.refreshWorkBenchConfig(true)
  sortedCategoryApps.value = cacheConfigStore.getSortedCategories()
  appListStore.initApp()
})
</script>

<template>
  <div class="workbench" :style="[bgStyle]">
    <div class="app-list flex flex-col flex-wrap">
      <div v-for="category in sortedCategoryApps" :key="category.id" mb-40px>
        <div text-lg font-bold pl-20px>
          {{ category.name }}
        </div>
        <div style="border-bottom:1px solid gray" my-10px />
        <div flex>
          <div
            v-for="app in category.apps"
            :key="app.code"
            class="item flex"
            flex-col items-center
            @click="appListStore.openApp(app.id)"
          >
            <img :src="app.icon">
            <a-tooltip :title="app.name" color="blue">
              <div class="name ellipsis" w-112px text-center>
                {{ app.name }}
              </div>
            </a-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.workbench {
  padding: 50px;
  // width: 100vw;
  height: calc(100vh - 48px);
  margin: 0 -10px -10px;
  background-image: url(./images/bg.png);
  background-size: cover;
  background-position: 50%;
  .item {
    line-height: px;
    padding: 4px 8px;
    cursor: pointer;
    margin: 0 16px 20px;
    &.active {
      border: 1px solid blue;
    }
    img {
      width: 60px;
      height: 60px;
      margin-bottom: 10px;
    }
    .name {
      color: #333;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
    }
  }
}
</style>
